import React from 'react'
import { Link } from 'react-router-dom'
import './Donate.scss'
function Donate() {
  return (
    <div className='containet container'>
      <div className='col-12  '>
        <div className=' alignContent'>
          <div className='text-center pt-5'>
            <button className='btn btn-warning fw-bold' data-bs-toggle="collapse" data-bs-target="#collapseExample" aria-expanded="false" aria-controls="collapseExample">Donate Gadgets <i className="fa fa-chevron-down" aria-hidden="true"></i>
            </button>
            <div className="collapse" id="collapseExample">
              <form className='card mt-3 bg-light' content-type='application/x-www-form-urlencoded' method='post' action='tech2grassroots.com.ng/adminserver/api/donate'>
                <div className='text-danger p-2 fw-bold'>Donate Items</div>
                <div className='p-3'>
                  <input placeholder='Name' className='form-control mt-4' name='name' required />
                  <input placeholder='email' className='form-control mt-4' name='email' required/>

                  <input placeholder='phone' className='form-control mt-4' name='phone' required/>

                  <textarea placeholder='what you want Donate' className='form-control mt-4' name='item' required/>

                 <Link to='/submit'><button className='btn btn-secondary mt-4 fw-bold'>
                    Submit for follow up
                  </button></Link> 

                </div>
              </form>
            </div>
          </div>
          <div className='text-center mt-5'>
            <a href='https://paystack.com/pay/tech2grassroots'><button className='btn btn-success  fw-bold'>Donate Money <i className="fa fa-arrow-right" aria-hidden="true"></i></button></a>
          </div>

        </div>
      </div>

    </div>
  )
}

export default Donate