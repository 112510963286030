import React from 'react'
import { Link } from 'react-router-dom'
import Logo from './assert/images/logo-removebg-preview.png'
import CustomLink from './component/helper/CustomLink/customlink'

export default function Navbar() {
    return (
        <nav className='navbar navbar-expand-lg border-bottom border-warning overflow-hidden navbar-light bg-light'>
            <div className='container-fluid'>
                <div className='row'>
                    <div className='col'>
                        <Link to='/' className='navbar-brand' >
                            <img src={Logo} alt='logo' width={'100%'} />
                        </Link>
                    </div>
                    <div className='col text-end'>
                        <button className='navbar-toggler fs-6' typeof='button' data-bs-toggle='collapse' data-bs-target='#navbarSupportedContent' aria-controls='navbarSupportedContent' aria-expanded='false' aria-label='toggle navigation'>

                            <span className="navbar-toggler-icon"></span>
                        </button>
                    </div>

                </div>


                <div className='collapse navbar-collapse' id='navbarSupportedContent'>
                    <ul className='navbar-nav ms-auto text-center mb-lg-0'>
                        <li className='nav-item m-2'>
                            <CustomLink to='/' className='nav-link fw-bold text-dark' >Home</CustomLink>
                        </li>
                       {/*<li className='nav-item m-2'>
                            <CustomLink to='/about' className='nav-link fw-bold text-dark' >About</CustomLink>
    </li> */}
                        <li className='nav-item m-2'>
                            <CustomLink to='/Gallery' className='nav-link fw-bold text-dark'>Gallery</CustomLink>
                        </li>
                        <li className='nav-item m-2'>
                            <CustomLink to='/Contact' className='nav-link fw-bold text-dark'>Contact</CustomLink>
                        </li>
                        <li className='nav-item m-2'> <CustomLink to='/Donate' className='nav-link fw-bold text-white rounded-pill bg-success'>Donate</CustomLink>
                        </li>

                        <li className='nav-item m-2'><CustomLink to='/Volunteer' className='nav-link fw-bold text-white bg-primary rounded-pill '>Volunteer</CustomLink>
                        </li>
                    </ul>


                </div>
            </div>
        </nav>
    )
}



