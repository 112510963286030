import 'bootstrap/dist/css/bootstrap.min.css';
import './Gallery.css';
import { Carousel } from 'react-bootstrap';

const Gallery = ({ imageUrls, headImageUrls }) => { 
  return (
    <>
      <h3 className="">Welcome to the Gallery</h3>
      <Carousel>
        {headImageUrls.map((headImageUrl, index) => (
          <Carousel.Item key={index} style={{ height: '450px' }}>
            <img className="d-block w-100 carousel-image" src={headImageUrl} alt={`Slide ${index + 1}`} />
          </Carousel.Item>
        ))}
      </Carousel>
      <div className="container">
        <div className="row">
          {imageUrls.map((imageUrl, index) => (
            <div key={index} className="col-md-4 mb-4">
              <div className="image-container">
                <img src={imageUrl} className="img-fluid rounded" alt={`img${index + 1}`}/>
              </div>
            </div>
          ))}
        </div>
      </div>
    </>
  )
  };

export default Gallery;
