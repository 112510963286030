import React from 'react'
import Aboutdisplay from './component/aboutDisplay/aboutdisplay'
import Landinghead from './component/landingdisplay/landinghead'
import LatestEvent from './component/latestEvent/latestEvent'
import Partners from './component/partners/Partners'

function Home() {
  return (
    <>
      <Landinghead />
      <Aboutdisplay />
      <LatestEvent />
      <Partners/>
    </>
  )
}

export default Home