import React from 'react'
import bLogo from './assert/images/logo-removebg-preview.png'
import { Routes } from 'react-router-dom'

function Footer() {
  return (
    <div className='container-fluid border-top'>
        <div className='col-5'>
                <img src={bLogo} alt='data' width={'100%'}/>
        </div>
        <div className='row row-cols-lg-4 row-cols-2  container'>
            <div className='col mt-3 p-3'>
                <h5>Socials</h5>
                <h6>Facebook</h6>
                <h6>Youtube</h6>
                <h6>Linkedin</h6>
                <h6>Whatsapp</h6>
            </div>
       
            <div className='col mt-3  p-3'>
                <h5>Address</h5>
                <h6>Kwara state library complex,post office area, Ilorin, Kwara state</h6>
            </div>

            <div className=' col mt-3 p-3'>
                <h5>Email Address</h5>
                <h6>info@tech2grassroots <br/>.com.ng</h6>
            </div>

        </div>
        <div className='text-center border-top fw-bold'>
            
© Copyright 2021-2023 || <span className='text-danger'>powered by Tech2grassroots</span>
        </div>
    </div>
  )
}

export default Footer