import React from 'react'
import O from '../../../../../../assert/partners/1.jpg'
import P from '../../../../../../assert/partners/2.png'
import Q from '../../../../../../assert/partners/3.png'
import R from '../../../../../../assert/partners/4.png'
import S from '../../../../../../assert/partners/5.png'
import T from '../../../../../../assert/partners/6.png'
import './Partners.scss'
function Partners() {
  return (
    <div>
        <div className="slider">
	<div className="slide-track mt-4">
		<div className="slide p-2">
			<img src={O} className='px-1' height="50" width="150" alt="" />
		</div>
		<div className="slide p-2">
			<img src={P} className='px-1' height="50" width="150" alt="" />
		</div>
		<div className="slide p-2">
			<img src={Q} className='px-1' height="50" width="150" alt="" />
		</div>
		<div className="slide p-2">
			<img src={R} className='px-1' height="50" width="150" alt="" />
		</div>
		<div className="slide p-2">
			<img src={S} className='px-1' height="50" width="150" alt="" />
		</div>
		<div className="slide p-2">
			<img src={T} className='px-1' height="50" width="150" alt="" />
		</div>
		<div className="slide p-2">
			<img src={O} className='px-1' height="50" width="150" alt="" />
		</div>
		<div className="slide p-2">
			<img src={P} className='px-1' height="50" width="150" alt="" />
		</div>
		<div className="slide p-2">
			<img src={Q} className='px-1' height="50" width="150" alt="" />
		</div>
		<div className="slide p-2">
			<img src={R} className='px-1' height="50" width="150" alt="" />
		</div>
		<div className="slide p-2">
			<img src={S} className='px-1' height="50" width="150" alt="" />
		</div>
		<div className="slide p-2">
			<img src={T} className='px-1' height="50" width="150" alt="" />
		</div>
		<div className="slide p-2">
			<img src={O} className='px-1' height="50" width="150" alt="" />
		</div>
		<div className="slide p-2">
			<img src={P} className='px-1' height="50" width="150" alt="" />
		</div>
	</div>
</div>
    </div>
  )
}

export default Partners