import React from 'react'
import { Link } from 'react-router-dom'

function Volunteer() {
  return (
    <div className='containet'>
      <div className='container-fluid'>
        <div className=' d-flex justify-content-center align-content-center'>
          <form className='col-lg-3 mt-5 p-5 bg-light rounded-3 shadow ' action='tech2grassroots.com.ng/adminserver/api/volunteer' method='post' content-type='application/x-www-form-urlencoded'>
            <h2 className='mb-5 text-center'>Volunteer</h2>
            <input placeholder='name' className='form-control' name='name' required/>
            <input placeholder='phone' className='form-control mt-4' name='phone' required/>
            <input placeholder='email' className='form-control mt-4' name='email' required/>
            <textarea placeholder='Reason you want to volunteer' className='form-control mt-4' required name='reason'/>
            <div className='text-center'>
              <Link to='/submit'><button typeof='submit' className='btn btn-primary mt-5 w-50 '> Submit </button></Link>
            </div>
          </form>
        </div>


      </div>
    </div>
  )
}

export default Volunteer