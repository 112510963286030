import React from 'react'
import './aboutDisplay.scss'
import afric from '../../../../../../assert/carousel/about/afr.jpg'
import chid from '../../../../../../assert/carousel/about/chi.jpg'
import grup from '../../../../../../assert/carousel/about/grp.jpg'
function Aboutdisplay() {
    return (
        <>
            <div className='bg-white'>
                <div className='container-fluid mt-5 pt-2'>
                    
                    <div className='row row-cols-lg-2 pt-5'>

                        <div className='col bg-text text-dark p-4 '>
                        <h4><u>About</u></h4>
                            <p className='align-self-center'>Tech2grassroots helps to reduce the burden of educational inequality in Africa by providing access to digital education & tools towards developing the capacity of teachers and students in disadvantaged communities for e-learning.
                            </p>
                            <div className='mt-5'>
                            <button className='btn btn-success fw-bold'>Donate</button>
                            </div>
                           
                        </div>
                        <div>
                            <div id="carouselExampleCaptions" className="carousel slide " data-bs-ride="carousel">
                                <div className="carousel-indicators">
                                    <button type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide-to="0" className="active" aria-current="true" aria-label="Slide 1"></button>
                                    <button type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide-to="1" aria-label="Slide 2"></button>
                                    <button type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide-to="2" aria-label="Slide 3"></button>
                                </div>
                                <div className="carousel-inner col-2 main-carou">
                                    <div className="carousel-item active carou-styl--backg">
                                        <img src={afric} className="d-block w-100 carou-styl rounded-5" alt="..." />

                                        <div className="middle text">
                                            <h5 className='bg-white text-dark'>Educational Technology</h5>
                                            <p>We take education to remote areas where there isn't, increasing digital literacy in those areas.</p>

                                        </div>

                                    </div>
                                    <div className="carousel-item carou-styl--backg">
                                        <img src={chid} className="d-block w-100 carou-styl" alt="..." />
                                        <div className=" middle text ">
                                            <h5 className='bg-white text-dark'>Future Technology</h5>
                                            <p>Future tech outreach to help bring the next generation of tech innovators.</p>
                                        </div>
                                    </div>
                                    <div className="carousel-item carou-styl--backg">
                                        <img src={grup} className="d-block w-100 carou-styl" alt="..." />
                                        <div className="middle text">
                                            <h5 className='bg-white text-dark'>Digital World</h5>
                                            <p>Exposure to recent happenings in the tech world, such as latest programming languages and innventions.</p>
                                        </div>
                                    </div>
                                </div>
                                <button className="carousel-control-prev" type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide="prev">
                                    <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                                    <span className="visually-hidden">Previous</span>
                                </button>
                                <button className="carousel-control-next" type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide="next">
                                    <span className="carousel-control-next-icon" aria-hidden="true"></span>
                                    <span className="visually-hidden">Next</span>
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Aboutdisplay