import React from 'react'
import { Link, Navigate, useNavigate } from 'react-router-dom'
import './Contact.scss'
function Contact() {
  const navigate = useNavigate()
  return (
    <div className='contain'>
      <div className='container-fluid'>
        <div className=' d-flex justify-content-center align-content-center'>
          <form className='col-lg-3 mt-5 p-5 bg-light rounded-3 shadow ' method='post' action='https://tech2grassroots.com.ng/adminserver/api/contact' content-type='application/x-www-form-urlencoded'>
            <h2 className='mb-5 text-center'>Contact Us</h2>
            <input placeholder='name' className='form-control' required name='name' />
            <input placeholder='phone' className='form-control mt-4' required name='phone' />
            <input placeholder='email' className='form-control mt-4' required name='email' />
            <div className='text-center'>
              <button typeof='submit' onSubmit={() => navigate('/submit')} className='btn btn-primary mt-5 w-50 '> Submit </button>
            </div>
          </form>
        </div>

        <div className='bg-light mt-5 p-5'>
          <div className='row row-cols-4 text-center'>

            <div className='col'>
              <a href='https://wa.me/+2348066406464' className='link'><i className="fa fa-whatsapp fs-1 fw-bold" aria-hidden="true"><span className='fs-4'></span></i> </a>
            </div>

            <div className='col'>
              <a href='https://wa.me/+2348066406464' className='link'><i className="fa fa-facebook fs-1 fw-bold" aria-hidden="true"><span className='fs-4'></span></i> </a>
            </div>

            <div className='col'>
              <a href='https://wa.me/+2348066406464' className='link'><i className="fa fa-youtube-play fs-1 fw-bold" aria-hidden="true"><span className='fs-4'></span></i> </a>
            </div>

            <div className='col'>
              <a href='https://wa.me/+2348066406464' className='link'><i className="fa fa-linkedin-square fs-1 fw-bold" aria-hidden="true"><span className='fs-4'></span></i> </a>
            </div>
          </div>

        </div>
      </div>
    </div>
  )
}

export default Contact