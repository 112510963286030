import React from 'react'
import './landing.scss'
import brandLogo from '../../../../../../assert/images/brand-PhotoRoom.png-PhotoRoom.png'
import girlP from '../../../../../../assert/images/kingseedwo.png'
import botK from '../../../../../../assert/images/bothand.png'
import 'bootstrap/dist/css/bootstrap.min.css';


function Landinghead() {
    return (
        <>
            <div className='bg-head-big text-center' >
                <div className='col ' data-aos='fade-up'>

                    <div className='brand-size  col-5'>
                        <img src={brandLogo} alt='brandlogo' width={'50%'} className='mt-5' />
                    </div>
                    <div className='container row row-cols-2'>
                        <div className='col-9'>
                            <div className='heading-text text-center'>
                                <div className='text-heading text-center'>Tech2grassroots</div>
                                <div className='row'>
                                    <div className='col-12 text-heading--sub text-center'>
                                        <u> Closing digital gap...</u>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='col-3'>
                                <div className='girlPost'>
                                    <img src={girlP} alt="girl" className='girlPost-side'  />
                                    <img src={botK} alt="bothand" className='botPost-side'/>
                                </div>
                        </div>
                    </div>
                    <div className='row row-cols-md-1 row-cols-lg-1 container-fluid mt-5 button-container'>
                        <div className='col mt-5'>
                         <a href='https://conference-landing-page-rcux.vercel.app/' target='_blank' rel='noreferrer'>  <button type="button" className="btn btn-primary btn-lg">Registration For Conference</button></a>
                        </div>

                    </div>
                </div>
            </div>
    </>

    )
}

export default Landinghead