import React from 'react';
import { Route, Routes } from 'react-router-dom';
import { About, Contact, Donate, Gallery, Home, Services, Submit, Volunteer, YearofSDG } from './component/interfaces/pages';
import Footer from './footer';
import Navbar from './navbar';
import 'bootstrap/dist/css/bootstrap.min.css';


const headImageUrls = [
  '/images/img9.jpg',
  '/images/img2.jpg',
  '/images/img4.jpg',
  '/images/img5.jpg',
  '/images/img12.jpg',
]

const imageUrls = [
  '/images/img1.jpg',
  '/images/img2.jpg',
  '/images/img3.jpg',
  '/images/img4.jpg',
  '/images/img5.jpg',
  '/images/img6.jpg',
  '/images/img7.jpg',
  '/images/img8.jpg',
  '/images/img9.jpg',
  '/images/img10.jpg',
  '/images/img11.jpg',
  '/images/img12.jpg',
  '/images/img13.jpg',
  '/images/img14.jpg',
  '/images/img15.jpg',
  '/images/img16.jpg',
  '/images/img17.jpg',
  '/images/img18.jpg',
  '/images/img19.jpg',
  '/images/img20.jpg',
  '/images/img21.jpg',
  '/images/img22.jpg',
  '/images/img23.jpg',
  '/images/img24.jpg',
  '/images/img25.jpg',
  '/images/img26.jpg',
  '/images/img27.jpg',
  '/images/img28.jpg',
  '/images/img29.jpg',
  '/images/img30.jpg',
  '/images/img31.jpg',
];

function App() {
  return (
    <>
    <Navbar/>
      <div className='main'>
        
        <Routes>
          <Route element={<Home />} path='/' />

          <Route element={<About/>} path='/about'/>

          <Route element={<Contact/>} path='/contact'/>

          <Route element={<Donate/>} path='/donate'/>

          <Route element={<Gallery headImageUrls={headImageUrls} imageUrls={imageUrls}/>} path='/gallery'/>

          <Route element={<Services/>} path='/services'/>

          <Route element={<Volunteer/>} path='/volunteer'/>

          <Route element={<YearofSDG/>} path='/yearofSDG'/>

          <Route element={<Submit/>} path='/submit'/>


            
        </Routes>
      </div>
      <Footer />
    </>

  );
}

export default App;