import React from 'react'
import './latestEvent.scss'
import KwaraDe from '../../../../../../assert/events/events4.jpg'
import BlockChain from '../../../../../../assert/events/events1.jpg'
import Ksl from '../../../../../../assert/events/ksl.jpg'
import Ksle from '../../../../../../assert/events/texh2school.jpg'
import T4W from '../../../../../../assert/events/events2.jpg'
import T2G from '../../../../../../assert/events/events3.jpg'




export default function latestEvent() {
    return (
        <div className='set'>
            <div className='text-white'>
                <h5 className='text-white container-fluid'>Latest Events</h5>
                <section>
                    <div className="container">
                        <div className="carousel">
                            <input type="radio" name="slides" id="slide-1" />
                            <input type="radio" name="slides" id="slide-2" />
                            <input type="radio" name="slides" id="slide-3" />
                            <input type="radio" name="slides" id="slide-4" />
                            <input type="radio" name="slides" id="slide-5" />
                            <input type="radio" name="slides" id="slide-6" />
                            <input type="radio" name="slides" id="slide-7" />
                            <ul className="carousel__slides">
                                <li className="carousel__slide">
                                    <figure>
                                        <div >
                                            <img src={KwaraDe} alt="" className='imgc'/>
                                        </div>
                                        <figcaption>
                                            Kwara Digital Education
                                            <p className=''>Summit & Award 4.0</p>
                                            <span className="credit">Date: 24th January 2024</span>
                                        </figcaption>
                                    </figure>
                                </li>
                                <li className="carousel__slide">
                                    <figure>
                                        <div>
                                            <img src={BlockChain} alt="" className='imgc'/>
                                        </div>
                                        <figcaption>
                                            <p>Unveiling Digital Inclusivity Portal "ikoko"</p>
                                        </figcaption>
                                    </figure>
                                </li>
                                <li className="carousel__slide">
                                    <figure>
                                        <div>
                                            <img src={Ksl} alt="" className='imgc'/>
                                        </div>
                                        <figcaption>
                                            Kwara State E-Library 
                                            <p>ED-TECH HUB</p>
                                          
                                        </figcaption>
                                    </figure>
                                </li>
                                <li className="carousel__slide">
                                    <figure>
                                        <div>
                                            <img src={Ksle} alt="" className='imgc'/>
                                        </div>
                                        <figcaption>
                                            Tech2School
                                            <p>A Practical Digital Skill Workshop for Primary & Secondary Schools in Africa</p>

                                        </figcaption>
                                    </figure>
                                </li>
                                <li className="carousel__slide">
                                    <figure>
                                        <div>
                                            <img src={T4W} alt="" className='imgc'/>
                                        </div>
                                        <figcaption>
                                            <p>Invitation to Sponsor</p>
                                            <span>For sponsorship, contact: 07011198112, 08066406464</span>
                                            <p>info@tech2grassroots.com</p>
                                        </figcaption>
                                    </figure>
                                </li>
                                
                                <li className="carousel__slide">
                                    <figure>
                                        <div>
                                            <img src={T2G} alt="" className='imgc'/>
                                        </div>
                                        <figcaption>
                                           Tech2grassroot
                                           <p>Support SDG in your community</p>
                                            <span>For sponsorship, contact: 07011198112, 08066406464</span>
                                            <p>info@tech2grassroots.com</p>
                                        </figcaption>
                                    </figure>
                                </li>
                            </ul>
                            <ul className="carousel__thumbnails">
                                <li>
                                    <label htmlFor="slide-1"><img src={KwaraDe} alt="" /></label>
                                </li>
                                <li>
                                    <label htmlFor="slide-2"><img src={BlockChain} alt="" /></label>
                                </li>
                                <li>
                                    <label htmlFor="slide-3"><img src={Ksl} alt="" /></label>
                                </li>
                                <li>
                                    <label htmlFor="slide-4"><img src={Ksle} alt="" /></label>
                                </li>
                                <li>
                                    <label htmlFor="slide-5"><img src={T4W} alt="" /></label>
                                </li>
                                <li>
                                    <label htmlFor="slide-7"><img src={T2G} alt="" /></label>
                                </li>
                            </ul>
                        </div>
                    </div>
                </section>
            </div>
        </div>
    )
}
