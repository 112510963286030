import React from 'react'
import { Link } from 'react-router-dom'
import Thumbs from '../../../../assert/images/thumbs.png'
function Submit() {
  return (
    <div className='text-center container'>
      <img src={Thumbs} alt='thumbs' width={'40%'}/>
    <h2>Thanks for the response we'll contact you as soon as possible</h2>  

    <Link to='/'><button className='btn btn-success mb-4'>Go home</button></Link>
    </div>
  )
}

export default Submit
